<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                                        <v-toolbar
                                        dark
                                        color="primary"
                                        >
                                        <v-toolbar-title>Plantilla de datos</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-toolbar-items>
                                            <v-btn
                                                dark
                                                text
                                                @click="sendTable()">
                                                Guardar
                                            </v-btn>
                                        </v-toolbar-items>
                                        </v-toolbar>
                                        <v-stepper
                                            v-model="stepperDataTemplate"
                                            vertical
                                        >

                                            <v-stepper-step
                                            :complete="stepperDataTemplate > 3"
                                            step="2"
                                            editable>
                                            Carga de información
                                            </v-stepper-step>

                                            <v-stepper-content step="2">
                                            <v-card
                                                color="grey lighten-1"
                                                class="mb-12"
                                                height="60px"
                                            >
                                            <div class="text-center">
                                                <v-btn
                                                    style="height: 45px;"
                                                    class="ma-2"
                                                    
                                                    color="secondary"
                                                    @click="$refs.fileInput.click()"
                                                > Seleccionar archivo importador de tablas </v-btn>
                                                <input type="file" :accept="SheetJSFT" ref="fileInput" @change="inputChange" />
                                            

                                                
                                            </div>
                                            </v-card>
                                            </v-stepper-content>

                                            <v-stepper-step 
                                                step="3"
                                                editable
                                            >
                                                Gráficos
                                            </v-stepper-step>
                                            <v-stepper-content step="3">
                                            <v-card
                                                color="grey lighten-1"
                                                class="mb-12"
                                                height="45px"
                                            >
                                            
                                            <v-select
                                                                    :items="Types"
                                                                    label="Tipo de graficas"
                                                                    v-model="dataTemplate.graph_type_id"
                                                                ></v-select>
                                            </v-card>
                                            </v-stepper-content>
                                            <v-stepper-step step="4" editable>
                                                Visualización de información
                                            </v-stepper-step>
                                            <v-stepper-content step="4">
                                            <v-card
                                                color="grey lighten-1"
                                                class="mb-12"
                                            >
                                             <div v-if="dataTemplate.graph_type_id == 1">
                                                <h3>Grafica lineal</h3>
                                                <line-chart :data="chartData" :messages="{empty: 'No data'}"></line-chart>
                                            </div>
                                            <div v-if="dataTemplate.graph_type_id == 2">
                                                <h3>Grafica en columnas</h3>
                                                <column-chart :data="chartData"  :messages="{empty: 'No data'}"></column-chart>
                                            </div>
                                            <div v-if="dataTemplate.graph_type_id == 3">
                                                <h3>Grafica de Pie</h3>
                                                <pie-chart :data="chartData" :messages="{empty: 'No data'}"></pie-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 4">
                                                <h3>Grafica tipo barras horizontales</h3>
                                                <bar-chart :data="chartData" :messages="{empty: 'No data'}"></bar-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 5">
                                                <h3>Grafica area bajo la linea</h3>
                                                <area-chart :data="chartData" :messages="{empty: 'No data'}"></area-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 9">
                                                <h3>Grafica Dispersion</h3>
                                                <scatter-chart :data="chartData" :messages="{empty: 'No data'}"></scatter-chart>
                                            </div>

                                            <div  v-if="dataTemplate.graph_type_id == 6">
                                                <h3>Grafica tipo barras horizontales</h3>
                                                <bar-chart :data="barsData" :messages="{empty: 'No data'}"></bar-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 7">
                                                <h3>Grafica area bajo la linea</h3>
                                                <area-chart :data="barsData" :messages="{empty: 'No data'}"></area-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 8">
                                                <h3>Grafica Dispersion</h3>
                                                <scatter-chart :data="barsData" :messages="{empty: 'No data'}"></scatter-chart>
                                            </div>
                                            <div v-if="dataTemplate.graph_type_id == 10" class="speedometer__container">                                               
                                                    <div class="speedometer__container--gauge">
                                                        <div class="speedometer__container--gauge__title"><p>{{dataTemplate.name_table}}</p> </div>
                                                        <VueSvgGauge
                                                            :start-angle="-115"
                                                            :end-angle="115"
                                                            :value="value"
                                                            :separator-step="1"
                                                            :min="0"
                                                            :max="quantity"
                                                            :gauge-color="[{ offset: 0, color: 'red'},{ offset: valueMid, color: 'yellow'},{ offset: 100, color: 'green'}]"
                                                            :scale-interval="0.8"
                                                        />
                                                        <div  class="speedometer__container--gauge__details">
                                                            <div class="speedometer__container--details_high">
                                                            <p>{{highValueText}}</p><div class="square green"></div>
                                                            </div>
                                                            <!-- <div class="speedometer__container--details_high">
                                                            <p>Medio</p><div class="square yellow"></div>
                                                            </div> -->
                                                            <div class="speedometer__container--details_high">
                                                            <p>{{lowValueText}}</p><div class="square red"></div>
                                                            </div>
                                                            <div class="speedometer__container--details_high">
                                                            <p>{{labelResult}}</p><p>{{result}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="speedometer__container--description">
                                                            <div class="speedometer__container--description__principal">
                                                                <p>{{grayText}}</p>
                                                            </div>
                                                            <div class="speedometer__container--description__principal--price">
                                                                <p>{{greenText}}</p>
                                                            </div>
                                                            <div  class="speedometer__container--description__invest">
                                                                <p class="speedometer__container--description__invest--title">{{labelOne}}: </p><p>{{valueOne}}</p>
                                                            </div>
                                                            <div class="speedometer__container--description__future--invest">
                                                                <p class="speedometer__container--description__invest--title">{{labelTwo}}: </p><p>{{valueTwo}}</p>
                                                            </div>
                                                        </div>
                                                    
                                                </div>
                                                    <!--  or  -->
                                                    <!-- <vue-svg-gauge
                                                        :start-angle="-110"
                                                        :end-angle="110"
                                                        :value="5"
                                                        :separator-step="0"
                                                        :min="0"
                                                        :max="10"
                                                        gauge-color="#8CDFAD"
                                                        :scale-interval="0.1"
                                                    />
                                                    <VueSvgGauge
                                                        :start-angle="-110"
                                                        :end-angle="110"
                                                        :value="random"
                                                        :separator-step="20"
                                                        :scale-interval="10"
                                                        :inner-radius="80"
                                                    /> -->
                                            </div>
                                            </v-card>
                                            </v-stepper-content>
                                        </v-stepper>
                                    </v-card>
                                   
            


            </v-col>
        </v-row>
    </div>
</template>
<script>
import XLSX, { read } from "xlsx"
import JSZip, { files } from "jszip"
import { Bar } from 'vue-chartjs'

const _SheetJSFT = [ "zip" ].map(x => "." + x).join(",")
export default {
    data() {
        return {
            value:'',
            result:'',
            valueLow:'',
            valueMid:'',
            valueHigh:'',
            quantity:'10',
            finalprueba:[],
            dimensions:[],
            architectures:[],
            chartData: {},
            barsData:{},
            dataExcel: [],
            dataSend: [],
            loader: null,
            loading: false,
            btnDisable: true,
            SheetJSFT: _SheetJSFT,
            zip: null,
            xlsx: null,
            tables:[],
            dialogDataTemplate: false,
            dataTemplate: {
                id: 0,
                name_table: '',
                client_id: '',
                data_table: [],
                graph_types_id:'',
                fields: []
            },
            headers: [
                {
                    text: 'Nombre plantilla',
                    align: 'start',
                    value: 'name_table',
                },
                { text: 'Cliente', value: 'client' },
                { text: 'Acciones', value: 'actions', sortable: false },
            ],
            DataTemplates: [],
            stepperDataTemplate: 1,
            dataType: {
                id: 0,
                name_field: '',
                type_field: ''
            },
            dataTypes: [
                {
                    text: 'Entero',
                    value: 'int'
                },
                {
                    text: 'Real',
                    value: 'float'
                },
                {
                    text: 'Cadena',
                    value: 'string'
                },
                {
                    text: 'Texto',
                    value: 'text'
                },
                {
                    text: 'Fecha',
                    value: 'date'
                }
            ],
            Fields: [
                {
                    name_field: 'Nombre',
                    type_field: 'string',
                    orden: 1
                },
                {
                    name_field: 'Edad',
                    type_field: 'int',
                    orden: 2
                },
                {
                    name_field: 'Ciudad',
                    type_field: 'string',
                    orden: 3
                },
            ],
            Clients: [
        
            ],
            Types:[],
            highValueText:'',
            lowValueText:'',
            labelResult:'',
            grayText:'',
            greenText:'',
            labelOne:'',
            valueOne:'',
            labelTwo:'',
            valueTwo:'',
            totalValue:''

        }
    },
    methods: {
        getPercentByKpi(){
      this.value = (this.result/this.totalValue)*10
    },
        sendTable(dataJson,product,sub_category_product,name) {
            
            const graph_type_id = this.dataTemplate.graph_type_id;         

            // Definición de parametros para envio en la petición 
            const attributes = 
            {
                product_id: product,
                graph_type_id: graph_type_id,
                name: name,
                data: dataJson,
                sub_category_product_id: sub_category_product
            }

                this.$http
                .post("product_tables",{
                data: {
                    attributes
                },
                },
                { headers: { Authorization: "Bearer " + this.getCrypto() } }
                )
                .then((response) => {
                console.log(response)
                });

            
        },
        async inputChange(evt){
      const files = evt.target.files
      //console.log(files[0].name.substr(-4,4))
      if( files[0].name.substr(-4,4) == '.zip'){
        if(files && files[0]){
            JSZip.loadAsync(files[0])
            .then(
                zip => {
                this.zip = zip

                zip.forEach(async (relativePath, zipEntry) => {
                    if(zipEntry.name.split(".")[1] && zipEntry.name.split(".")[1] === "xlsx"){
                    const file = await zip.file(zipEntry.name).async("arraybuffer")                  
                    const wb = XLSX.read(file, {type: 'buffer'})
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    const data = XLSX.utils.sheet_to_json(ws, {header:1})
                    this.dataExcel = data
                    if(this.dataExcel!= null){
                        console.log('los datos',this.dataExcel)
                        this.convertJson(this.dataExcel)
                    }
                    }
                });
                },
                e =>   {
                alert("Error reading " + ": " + e.message)
                }
            )
        }
      }
      
      if( files[0].name.substr(-4,4) == 'xlsx'){                   
          this.handleFileLoad(evt)
        }
    },
    handleFileLoad(e) {
        if (e.target.files.length > 0) {
            try {
                    const file = e.target.files.async("arraybuffer")                  
                    const wb = XLSX.read(file, {type: 'buffer'})
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    const data = XLSX.utils.sheet_to_json(ws)
                    this.dataExcel = data
            } catch (exception) {
            }

            
        } else {
            toast("No files found", { type: "error" });
        }
    },
    convertJson(x){
        const info = []
        const twoInfo = []
        for( var i = 0 ; i < (x.length-1) ; i++){
          info.push(x[i+1])
        }
        console.log('que envio',info)
            
             console.log('que envio',info)
        for(var i = 1; i < info.length ; i++){
            let setData = []
            setData.push(x[0])
            setData.push(x[i])
            console.log(setData)
            let name = setData[1][13]
            let product = setData[1][14]
            let secretary = setData[1][15]
            console.log('datos',setData,'name',name,'product',product,'secretary',secretary)
            
            let DataJson=JSON.stringify(setData)

            this.sendTable(DataJson,product,secretary,name)

        }
    },
    
    quitarAcentos(cadena){
      const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'}
      return cadena.split('').map( letra => acentos[letra] || letra).join('').toString().toLowerCase()
    },
    mounted() {
    this.renderChart(data, options)
  },
        newTemplate(){
        },
        editTemplate(){},
        getData(){},
        addDataType(){

            (this.dataTemplate.fields).push({
                id: 0,
                name_field: this.dataType.name_field,
                type_field: this.dataType.type_field,
                orden: 1
            });

            this.dataType = {
                id: 0,
                name_field: '',
                type_field: ''
            };
        },
        getDataType(type){
            var typeData = '';
            switch (type) {
                case 'string':
                    typeData = 'Cadena';
                    break;
                case 'int':
                    typeData = 'Entero';
                    break;
            }
            return typeData;
        },
 
        async getTypes(){
            this.$http.get('graph_types')
                .then(response => {
                    const types = response.body
                    for(var i = 0; i < types.length ; i++){
                        const text = types[i].type_graph
                        const value = types[i].id
                        const obj = {
                            text:text,
                            value:value
                        }
                        this.Types.push(obj)
                    }
        })},
        
        
    },
    beforeMount(){
        this.getTypes() 
    }
}
</script>
<style scoped>
.speedometer__container{
  width: 820px;
  height: 240px;
  margin: 0 auto;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid rgb(2, 1, 1);
  
}
.speedometer__container--title{
  width: 100%;
}
.speedometer__container--title h2{
  text-align: left;
}
.speedometer__container--gauge{
  width: 100%;
  height: 240px;
  display: grid;
  grid-template-columns: 2fr 2fr 4fr;
  grid-template-rows: 1fr 3fr;
}
.speedometer__container--details_high{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.square{
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
}
.green{
  background-color: rgba(0, 128, 21,0.6);
}
.red{
  background-color: rgba(255, 0, 0,0.6);
}
.yellow{
  background-color: rgba(255, 255, 0,0.6);
}
.gauge{
    margin-left: 30px;
    width: 150px ;
    height: 150px;
    grid-row: 2;
}
.speedometer__container--gauge__details{
    font-size: 10px;
    margin: 0 20px 0 40px;
    grid-row: 2;
    width: auto;
    height: auto;
}
.speedometer__container--description{
    margin: 30px 30px 30px 30px ;
    display:flex;
    flex-wrap: wrap;
    width: 356px;
    grid-column: 3/4;
    grid-row: 1/3;
    
}


.speedometer__container--description__principal{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 356px;
    background: #D8D8D8;
}
.speedometer__container--description__principal p{
    text-align: center;
    padding: 0;
    margin:0
}
.speedometer__container--description__principal--price{
    margin-top: 3px;
    height: 38px;
    width: 356px;
    display:flex;
    justify-content: center;
    align-items: center;
    background: #69C061;
    border-radius: 0px 0px 20px 20px;
}
.speedometer__container--description__principal--price p{
    text-align: center;
    padding: 0;
    margin:0
}
.speedometer__container--description__invest{
    margin: 0 auto;
    margin-top: 24px;
    height: 34px;
    width: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;
}
.speedometer__container--description__invest p{
   padding: 0;
    margin: 0;
}
.speedometer__container--description__future--invest{
    margin: 0 auto;
    margin-top: 6px;
    height: 34px;
    width: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;
}
.speedometer__container--description__future--invest p{
    padding: 0;
    margin: 0;
}
.speedometer__container--gauge__title{
    margin: 20px 0 0 30px ;
}
.speedometer__container--gauge__title p{
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}
.speedometer__container--description__invest--title{
    font-weight: bold;
}

</style>
